body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  /* scrollbar-width: none; */
}

h2,p{
  margin: 0;
}


.p-inputtext{
  text-align: center;
}


th{
  width: 2rem;
  padding: 1rem 0rem;
  text-align: center;
  background-color: rgb(126 34 206);
  color: white;
}

td{
  width: 4rem;
  padding: 0.7rem 0rem;
  text-align: center;
  font-size: 0.8rem;
}

tbody{
  min-height: 10vh;
  /* max-height: 11vh; */
  height: 100%;
  scrollbar-width: none !important;
}

.p-column-header-content{
  /* width: 8rem; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-listbox{
  border: none;
  width: 100%;  
}

.p-listbox-list{
  border: none;
  width: 100%;   
}

.p-listbox-list-wrapper{
  width: 100%;
}

.p-listbox-item{
  padding: 0.5rem 1rem;
}

.p-datatable-wrapper{
  scrollbar-width: none;
}