.container{
    width: 100%;
    /* height: 4rem; */
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(126 34 206 );
}

p{
    color: white;
    font-size: 2.25rem;
    font-weight: 700;
    margin: 0;
}