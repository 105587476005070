.container{
    width: 100%;
    min-height: 88.2vh;
    max-height: 88.2vh;
    height: 88.2vh;
    display: flex;
    /* justify-content: center; */
    padding: 3rem;
    gap: 3rem;
    justify-content: center;

}

.sub_container1{
    width: 25rem;
    border-radius: 15px;
    background-color: rgb(243 244 246 );
    box-shadow: #a855f7 0px 3px 10px;
    padding: 1rem ;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* height: 16.5rem; */
}

.sub_container2{
    width: 35rem;
    border-radius: 15px;
    height: 100%;
    /* background-color: rgb(243 244 246 ); */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
    /* overflow: hidden; */
    /* padding: 1rem ; */
    /* height: 16.5rem; */
}

.month_picker{
    display: flex;
    justify-content: end;
    padding: 1rem 5rem;
}



.total_sub_div{
    display: flex;
    justify-content: center;
    width: 100%;
    /* padding: 1rem; */
}

.total_sub{
    width: 90%;
    background-color: white;
    text-align: center;
    color: #a855f7;
    padding:  1rem;
    border-radius: 10px;
}

.Nothing{
    width: 90%;
    background-color: green;
    text-align: center;
    color: white;
    padding: 2rem 1rem;
    border-radius: 10px;
}

.select_div_container{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    width: 100%;


}

.select_div{
    display: flex;
    width: 90%;
    background-color: white;
    border: 1px solid #a855f7;
    border-radius: 10px;
    /* padding: 1rem; */
    height: 12rem;
    overflow-y: scroll;
    scrollbar-width: none;
}

.animation{
    height: 25rem;
}


@media only screen and (max-width:1028px){

    .container{
        gap: 2rem;
        padding: 3rem 1rem;
    }

    .sub_container1{
        width: 20rem;
    }

    .sub_container2{
        width: 40rem;
        scrollbar-width: none;
    }
}


@media only screen and (max-width:728px) {
    .container{
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
    }

    .sub_container1{
        width: 100%;
    }

    .sub_container2{
        width: 100%;
    }
}