.container{
    width: 100%;
    min-height: 88.2vh;
    display: flex;
    justify-content: center;
    padding: 5rem;

}

.sub_container{
    width: 30rem;
    background-color: yellow;
    border-radius: 15px;
    background-color: rgb(243 244 246 );
    box-shadow: #a855f7 0px 3px 10px;
    padding: 2rem ;
    height: 16.5rem;
}

.date_input{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input{
    display: flex;
    justify-content: center;
    align-items: center;
}

.total_sub_div{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 1rem;
}

.total_sub{
    width: 75%;
    background-color: white;
    text-align: center;
    color: #a855f7;
    padding: 2rem 1rem;
    border-radius: 10px;

}